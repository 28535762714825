import React from 'react';
import { Star } from 'lucide-react';
import routesLogo from './Routes Logo Main BW 2000x900.png';
import softkarLogo from './Softkar_logo_bw_3.png';
import strideLogo from './Stride Logo.png';
import oneSwitchLogo from './OneSwitch Logo.png'

const testimonials = [
  {
    content: "RateMatePro has transformed our pricing strategy. We've seen a 30% increase in revenue since implementation.",
  },
  {
    content: "The insights provided by RateMatePro have been invaluable. Our fleet utilization has improved significantly.",
  },
  {
    content: "Outstanding platform that has helped us stay competitive in a dynamic market. The ROI has been exceptional.",
  },
  {
    content: "RateMatePro's real-time pricing capabilities have revolutionized how we manage our airport and city locations.",
  }
];

const imageStyles = [
  { width: "100%", height: "50px" },
  { width: "100%", height: "65px" },
  { width: "100%", height: "50px" },
  { width: "100%", height: "35px" },
];

// const companyLogos = [
//   {
//     name: "Routes Car Rental",
//     logo: "https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&w=300&h=150&q=80"
//   },
//   {
//     name: "One Switch Car Rental",
//     logo: "https://images.unsplash.com/photo-1607703703520-bb638e84caf2?auto=format&fit=crop&w=300&h=150&q=80"
//   },
//   {
//     name: "Softkar",
//     logo: "https://images.unsplash.com/photo-1603899122634-f086ca5f5ddd?auto=format&fit=crop&w=300&h=150&q=80"
//   },
//   {
//     name: "Elite Cars",
//     logo: "https://images.unsplash.com/photo-1611224885990-ab7363d1f2a9?auto=format&fit=crop&w=300&h=150&q=80"
//   }
// ];

const companyLogos = [
  {
    name: "One Switch Car Rental",
    logo: oneSwitchLogo
  },
  {
    name: "Softkar",
    logo: softkarLogo
  },
  {
    name: "Routes Car Rental",
    logo: routesLogo
  },
  {
    name: "Elite Cars",
    logo: strideLogo
  }
];

const Testimonials = () => {
  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900">Trusted by Industry Leaders</h2>
          <p className="mt-4 text-xl text-gray-600">
            See what our clients have to say about their experience with RateMatePro
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-md p-8 transition-transform duration-300 hover:-translate-y-1"
            >
              <div className="flex items-center space-x-1 mb-4">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="w-5 h-5 fill-current text-primary-500" />
                ))}
              </div>
              <p className="text-gray-600">{testimonial.content}</p>
            </div>
          ))}
        </div>

        {/* <div className="mt-16">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-12 items-center max-w-4xl mx-auto">
            {companyLogos.map((company, index) => (
              <img 
                key={index}
                src={company.logo} 
                alt={`${company.name} Logo`} 
                className="h-16 w-auto object-contain grayscale opacity-60 hover:opacity-100 transition-opacity duration-300" 
              />
            ))}
          </div>
        </div> */}
        {/* <div className="mt-16">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-12 items-center max-w-4xl mx-auto">
            {companyLogos.map((company, index) => (
              <div 
                key={index} 
                className="flex justify-center items-center h-24 w-32 aspect-[3/2] bg-gray-100 rounded-lg overflow-hidden"
              >
                <img 
                  src={company.logo} 
                  alt={`${company.name} Logo`} 
                  className="max-h-full max-w-full object-contain grayscale opacity-60 hover:opacity-100 transition-opacity duration-300"
                />
              </div>
            ))}
          </div>
        </div> */}

      {/* <div className="mt-16">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-12 items-center max-w-4xl mx-auto">
          {companyLogos.map((company, index) => (
            <div 
              key={index} 
              className="flex justify-center items-center bg-gray-100 rounded-lg overflow-hidden"
              style={{ width: "128px", height: "64px" }} // Fixed size container
            >
              <img 
                src={company.logo} 
                alt={`${company.name} Logo`} 
                className="grayscale opacity-60 hover:opacity-100 transition-opacity duration-300"
                style={{ 
                  width: "128px", // Force exact width
                  height: "64px", // Force exact height
                  objectFit: "contain", // Fit inside without cropping
                  objectPosition: "center" // Center-align logos
                }} 
              />
            </div>
          ))}
        </div>
      </div> */}


      {/* <div className="mt-16">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-12 items-center max-w-4xl mx-auto">
          {companyLogos.map((company, index) => (
            <div 
              key={index} 
              className="flex justify-center items-center bg-gray-100 rounded-lg overflow-hidden"
              style={{
                width: "100%", 
                height: "64px", 
                display: "flex", 
                padding: "10px" 
              }}
            >
              <div 
                className="flex justify-center items-center" 
                style={{
                  width: "100%", 
                  height: "100%", 
                  display: "flex"
                }}
              >
                <img 
                  src={company.logo} 
                  alt={`${company.name} Logo`} 
                  className="grayscale opacity-60 hover:opacity-100 transition-opacity duration-300"
                  style={{ 
                    width: "100%",  // Maintains aspect ratio
                    height: "auto", // Ensures it scales properly
                    maxWidth: "90%", // Ensures large images fit properly
                    maxHeight: "90%", // Prevents overly large logos
                    objectFit: "contain", // Prevents cropping
                    objectPosition: "center", // Centers the image
                    transform: "scale(1.3)", // Enlarges smaller logos
                    transition: "transform 0.3s ease-in-out", // Smooth hover effect
                  }} 
                />
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div className="mt-16">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-12 items-center max-w-4xl mx-auto">
          {companyLogos.map((company, index) => (
            <div 
              key={index} 
              className="flex justify-center items-center bg-gray-100 rounded-lg overflow-hidden"
              style={{
                width: "100%", 
                height: "80px", // Container height
                display: "flex", 
                padding: "10px" 
              }}
            >
              <div className="flex justify-center items-center" style={{ width: "100%", height: "100%" }}>
                <img 
                  src={company.logo} 
                  alt={`${company.name} Logo`} 
                  className="grayscale opacity-60 hover:opacity-100 transition-opacity duration-300"
                  style={{ 
                    objectFit: "contain", 
                    objectPosition: "center",
                    transition: "transform 0.3s ease-in-out",
                    ...(imageStyles[index % imageStyles.length]) // Apply width/height from array
                  }} 
                />
              </div>
            </div>
          ))}
        </div>
      </div>







      </div>
    </section>
  );
};

export default Testimonials;