import React from 'react';
import { LineChart } from 'lucide-react';

const About = () => {
  return (
    <div className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="lg:w-1/2">
            <h2 className="text-3xl font-bold text-gray-900 mb-6" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>
              Revolutionizing Car Rental Pricing
            </h2>
            <p className="text-lg text-gray-600 mb-8" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>
              RateMatePro empowers car rental businesses with intelligent pricing solutions that maximize revenue and streamline operations. Our advanced algorithms analyze market trends, competitor data, and demand patterns to help you make informed decisions.
            </p>
            <div className="flex items-center space-x-4">
              <LineChart className="h-12 w-12 text-emerald-500" />
              <div>
                <h4 className="font-semibold text-gray-900" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>Data-Driven Decisions</h4>
                <p className="text-gray-600" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>Make strategic choices backed by real-time market insights</p>
              </div>
            </div>
          </div>
          
          <div className="lg:w-1/2 mt-10 lg:mt-0">
            <img 
              src="https://images.unsplash.com/photo-1449965408869-eaa3f722e40d?auto=format&fit=crop&w=800&q=80" 
              alt="Car rental management dashboard" 
              className="rounded-lg shadow-xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;