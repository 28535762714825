import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
// import 'tailwindcss/tailwind.css'; // Tailwind CSS
import 'antd/dist/antd.css'; 
import './index.css';  




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
