import React, { useState } from "react";
import { BarChart2, User, Menu, X } from "lucide-react";
import { Link } from "react-router-dom";
import updatedLogo from "./updatedLogo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-md z-50 border-b border-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo and Name */}
          <Link to="/" className="flex items-center space-x-2">
            <img
              src={updatedLogo}
              alt="RateMatePro Logo"
              className="h-10 w-10"
            />
            <span
              className="text-xl font-bold text-gray-900"
              style={{ fontFamily: "'Inter', system-ui, sans-serif" }}
            >
              RateMatePro
            </span>
          </Link>

          {/* Desktop Navigation Links */}
          <div className="hidden md:flex items-center space-x-8">
            <Link
              to="/why-choose-us"
              className="text-gray-600 font-semibold transition hover:text-green-600"
            >
              Why Choose Us?
            </Link>
            <Link
              to="/contact"
              className="text-gray-600 font-semibold transition hover:text-green-600"
            >
              Contact Us
            </Link>
            <Link
              to="/login"
              className="flex items-center text-gray-600 font-semibold transition hover:text-green-600"
            >
              <User className="h-4 w-4 mr-2" />
              Login
            </Link>
            <button
              className="btn-primary font-semibold"
              onClick={() => {
                window.location.href =
                  "https://calendar.google.com/calendar/appointments/schedules/AcZssZ3WSw1qjrR2hccl9DJLPHj-Ysh9yS4VBQkXcPCVtSwIcoqaT-m2aaiiG1tQRT0IcJEmRHDEEPJh?gv=true";
              }}
            >
              Book a Demo
            </button>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden flex items-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-300"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>
      </div>

      {/* Mobile Menu (Hidden by Default) */}
      {isOpen && (
        <div className="md:hidden absolute top-16 left-0 w-full bg-white shadow-lg border-t border-gray-200">
          <div className="flex flex-col space-y-4 py-4 px-6">
            <Link
              to="/why-choose-us"
              className="text-gray-600 font-semibold transition hover:text-green-600"
              onClick={() => setIsOpen(false)}
            >
              Why Choose Us?
            </Link>
            <Link
              to="/contact"
              className="text-gray-600 font-semibold transition hover:text-green-600"
              onClick={() => setIsOpen(false)}
            >
              Contact Us
            </Link>
            <Link
              to="/login"
              className="flex items-center text-gray-600 font-semibold transition hover:text-green-600"
              onClick={() => setIsOpen(false)}
            >
              <User className="h-4 w-4 mr-2" />
              Login
            </Link>
            <button
              className="btn-primary font-semibold"
              onClick={() => {
                window.location.href =
                  "https://calendar.google.com/calendar/appointments/schedules/AcZssZ3WSw1qjrR2hccl9DJLPHj-Ysh9yS4VBQkXcPCVtSwIcoqaT-m2aaiiG1tQRT0IcJEmRHDEEPJh?gv=true";
                setIsOpen(false);
              }}
            >
              Book a Demo
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
