import React from 'react';
import './Navibar.css'
// import logo from './RMPlogo1.png'
import logo from './updatedLogo.png'
import { IoLogOut } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import AccountPopover from './AccountPopover';
import {BrowserRouter as Router, Link} from 'react-router-dom';


export default function NavibarCollasped({setLoginSuccess}) {

  const navigate = useNavigate();

  const handleLogout = () => {
    
    setLoginSuccess(false)
    navigate("/", { replace: true });
    sessionStorage.clear();
  }
  return (
    <nav
      className="navbar navibar"
      // className={`navbar ${!navibar ? "navibar" : "navibar2"}`}
      style={{
        // backgroundColor: "#54ADD1",
        backgroundColor: "#008080",
        // backgroundColor: '#FFDB58'
        // backgroundColor:'beige'
      }}
    >
      <div className="container-fluid">
        <div className="row w-100 align-items-center">
          {/* Left Section (Logo + Text) */}
          <div className="col-auto d-flex align-items-center">
              <Link
                to="/"
                className="d-flex align-items-center text-decoration-none"
              >
                <img
                  src={logo}
                  style={{
                    width: "auto", 
                    // maxWidth: "60px", 
                    height: "50px", 
                    objectFit: "contain", 
                    marginLeft: "0px", 
                    marginTop: "0px",
                    // marginTop: '-7%'
                  }}
                  alt="Logo"
                  className="logo"
                />
                <span
                  className="ms-2 fw-bold"
                  style={{
                    color: "black",
                    fontSize: "25px",
                    height: "auto",
                    // marginTop: '-10%'
                  }}
                >
                Rate Mate Pro
              </span>
            </Link>
          </div>

      {/* Right Section (Account Popover) */}
      <div className="col d-flex justify-content-end">
        <AccountPopover setLoginSuccess={setLoginSuccess} />
      </div>
    </div>
  </div>
</nav>
    // <nav className="navbar navibar" style={{backgroundColor:'#54ADD1'}}>
    //   <div className="container-fluid ">
    //     <div className="row" style={{ width: '100%', marginLeft: '-3px' }}>
    //       <div className="col-auto" >
    //           <Link to="/">  <img src={logo} style = {{marginLeft:'-2px', width: '50px', height: 'auto'}}  alt="" className='logo' /> 
    //           <span className="ms-1" style={{color:'black', fontSize:'25px', marginLeft:'-5px', }}><b> Rate Mate Pro66</b></span>
    //           </Link>
    //       </div>
    //       {/* <div className="col-auto">
    //       <Link to="/">  <img src={logo} alt="" className='logo' /> </Link>
    //       </div> */}

    //       <div className="col d-flex justify-content-end">
    //         {/* <IoLogOut style={{ marginTop: 5, fontSize: 35, marginRight: 17 }} onClick={() => { handleLogout()}}/> */}
    //         <AccountPopover setLoginSuccess={setLoginSuccess}/>
    //       </div>
    //     </div>

    //   </div>
    // </nav>
  )
}
