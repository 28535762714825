import React from 'react';
import { BarChart2, Linkedin } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center mb-4">
              <BarChart2 className="h-8 w-8 text-emerald-500" />
              <span className="ml-2 text-xl font-bold">RateMatePro</span>
            </div>
            <p className="text-lg text-gray-400">
              Empowering car rental businesses with intelligent pricing solutions.
            </p>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold text-white mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><a href="/" className="text-lg text-gray-400 hover:text-emerald-500">Home</a></li>
              <li><a href="/why-choose-us" className="text-lg text-gray-400 hover:text-emerald-500">About Us</a></li>
              {/* <li><a href="#" className="text-lg text-gray-400 hover:text-emerald-500">Resources</a></li> */}
              <li><a href="/contact" className="text-lg text-gray-400 hover:text-emerald-500">Contact</a></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold text-white mb-4">Contact</h3>
            <ul className="text-lg space-y-2 text-gray-400">
              {/* <li>info@ratematepro.com</li> */}
              <li >
              <a href="mailto:info@ratematepro.com" className="text-lg text-gray-400 hover:text-emerald-500">
                info@ratematepro.com
              </a>
            </li>
            </ul>
          </div>
          
          {/* <div>
            <h3 className="text-lg font-semibold mb-4">Connect With Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-emerald-500" aria-label="LinkedIn">
                <Linkedin className="h-6 w-6" />
              </a>
            </div>
          </div> */}

          <div>
            <h3 className="text-lg font-semibold text-white mb-4">Connect With Us</h3>
            <div className="flex space-x-4">
              {/* <a href="https://www.linkedin.com/company/ratematepro/?viewAsMember=true" className="text-gray-400 hover:text-emerald-500" aria-label="LinkedIn">
                <Linkedin className="h-6 w-6" />
              </a> */}
              <a
                href="https://www.linkedin.com/company/ratematepro/?viewAsMember=true"
                target="_blank" 
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-emerald-500 transition-colors duration-200"
                aria-label="LinkedIn"
              >
                <Linkedin className="h-6 w-6" />
              </a>
              {/* #New Tab */}
              {/* <a
                href="https://www.linkedin.com/company/ratematepro/?viewAsMember=true"
                target="_blank" 
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-emerald-500 transition-colors duration-200"
                aria-label="LinkedIn"
              >
                <Linkedin className="h-6 w-6" />
              </a> */}

            </div>
          </div>
        </div>
        
        <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} RateMatePro. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;