
import { Mail } from 'lucide-react';
import React, { useState } from 'react';
import { baseURL } from '../pages/backend_url';
import axios from 'axios';
import Swal from 'sweetalert2';

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
     // Collect form data
      const formData = new FormData(e.target);
      const firstName = formData.get("firstName");
      const lastName = formData.get("lastName");
      const companyName = formData.get("companyName");
      const email = formData.get("email");
      const message = formData.get("message");
      console.log('formData',formData)
      console.log('firstName',firstName)
      console.log('lastName',lastName)
      console.log('email',email)
      console.log('message',message)

      // Basic validation
      if (!firstName || !lastName || !companyName|| !email || !message) {
        alert("Please fill in all fields.");
        return;
      }

      // Simulating form submission (Replace this with an actual API call)
      console.log("Form Submitted", { firstName, lastName,companyName, email, message });
      console.log('Form Data Submitted:', formData);

    const body_payload = {
      firstName : firstName,
      lastName : lastName,
      companyName: companyName,
      email : email,
      message : message,
      created_date: new Date()
    }
    const res2 =  axios.post(baseURL + "/save_contact_us_request", body_payload);
    console.log('res2',res2)

    // import Swal from 'sweetalert2';

    Swal.fire({
      title: 'Thank you for reaching out to us.',
      text: "We’ll get back to you shortly.",
      icon: 'success',
      showConfirmButton: false, // Optional: remove confirm button
      timer: 5000, // The alert will stay open for 50 seconds (50,000 ms)
      customClass: {
        popup: 'swal-popup',
      },
      didOpen: () => {
        // Apply inline styles directly to the popup
        const popup = document.querySelector('.swal-popup');
        if (popup) {
          // popup.style.width = '400px';
          // popup.style.padding = '20px';
          // popup.style.backgroundColor = '#212227';
          // popup.style.borderRadius = '47px';
          // popup.style.color = '#AA7AEB';
          // popup.style.fontFamily = 'Manrope, sans-serif';
          // popup.style.fontSize = '20px';
          // popup.style.fontWeight = '600';
          popup.style.width = '400px';
          popup.style.padding = '20px';
          popup.style.backgroundColor = '#198754';
          popup.style.borderRadius = '47px';
          popup.style.color = 'white';
          popup.style.fontFamily = 'Manrope, sans-serif';
          popup.style.fontSize = '20px';
          popup.style.fontWeight = '600';
        }

        // Apply styles to the title
        const title = document.querySelector('.swal-popup h2');
        if (title) {
          // title.style.textAlign = 'center';
          // title.style.fontSize = '24px';
          // title.style.color = '#AA7AEB';
          // title.style.marginTop = '15px';
          title.style.textAlign = 'center';
          title.style.fontSize = '24px';
          title.style.color = 'white';
          title.style.marginTop = '15px';
        }

        // Apply styles to the content text (same as title styles)
        const content = document.querySelector('.swal-popup .swal-text');
        if (content) {
          // content.style.fontSize = '20px'; // Same font size as title
          // content.style.color = '#AA7AEB'; // Same color as title
          // content.style.textAlign = 'center';
          // content.style.marginBottom = '10px';
          content.style.fontSize = '20px'; // Same font size as title
          content.style.color = 'white'; // Same color as title
          content.style.textAlign = 'center';
          content.style.marginBottom = '10px';
        }
      }
    }).then(() => {
      // Redirect after the alert closes (timer completes)
      window.location.href = '/'; // Redirect to the home page or any other URL
    });

      // Reset the form after submission
      e.target.reset();

      // Show a success message (Replace this with a better UI alert)
      // alert("Message sent successfully!");
  };

  return (
    <div className="py-20 bg-gradient-radial from-emerald-50 via-white to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Get in Touch with RateMatePro</h1>
          <p className="text-xl text-gray-600">We'd love to hear from you! Let us know how we can help.</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-stretch">
  {/* Left Form Content */}
  <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col">
    <form onSubmit={handleSubmit} className="space-y-6 flex-grow">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            required
          />
        </div>
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            required
          />
        </div>
      </div>
      <div>
        <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">Company Name</label>
        <input
          type="text"
          id="companyName"
          name="companyName"
          className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
          required
        />
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
          required
        />
      </div>

      <div>
        <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
        <textarea
          id="message"
          name="message"
          rows={4}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
          required
        ></textarea>
      </div>
      {/* className="btn-primary w-full text-lg" */}

      <button type="submit" className="btn-primary w-full text-lg">
        Send Message
      </button>
    </form>
  </div>

  {/* Right Side Content (Contact Info & Schedule Meeting) */}
  <div className="flex flex-col space-y-6">
    <div className="bg-white rounded-xl shadow-lg p-8 flex-grow">
      <h3 className="text-xl font-semibold text-gray-900 mb-6">Contact Information</h3>
      <div className="space-y-4">
        <div className="flex items-start space-x-4">
          <Mail className="w-6 h-6 text-primary-500 mt-1" />
          <div>
            <p className="!font-medium">Email</p>
            <a href="mailto:info@ratematepro.com" className="text-gray-600 hover:!text-primary-500 text-lg">
              info@ratematepro.com
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white rounded-xl shadow-lg p-8 flex-grow">
      <h3 className="text-xl font-semibold text-gray-900 mb-6">Schedule a Meeting</h3>
      <p className="text-gray-600 mb-4 !text-lg">
        Book a time that works best for you to discuss how RateMatePro can help optimize your rental business.
      </p>
      <button onClick={() => {
        window.location.href =
          "https://calendar.google.com/calendar/appointments/schedules/AcZssZ3WSw1qjrR2hccl9DJLPHj-Ysh9yS4VBQkXcPCVtSwIcoqaT-m2aaiiG1tQRT0IcJEmRHDEEPJh?gv=true";
      }} className="btn-secondary w-full">
        Schedule Now
      </button>
    </div>
  </div>
</div>


        {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="bg-white rounded-xl shadow-lg p-8">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                    required
                  />
                </div>
              </div>
              <div>
                  <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">Company Name</label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                    required
                  />
              </div>

              <div>
                <label htmlFor="email" className="block !text-sm !font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  required
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  required
                ></textarea>
              </div>

              <button type="submit" className="btn-primary w-full text-lg">
                Send Message
              </button>
            </form>
          </div>

          <div className="space-y-8">
            <div className="bg-white rounded-xl shadow-lg p-8">
              <h3 className="text-xl font-semibold text-gray-900 mb-6">Contact Information</h3>
              <div className="space-y-4">
                <div className="flex items-start space-x-4">
                  <Mail className="w-6 h-6 text-primary-500 mt-1" />
                  <div>
                    <p className="!font-medium">Email</p>
                    <a href="mailto:info@ratematepro.com" className="text-gray-600 hover:!text-primary-500 text-lg">
                      info@ratematepro.com
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-8">
              <h3 className="text-xl font-semibold text-gray-900 mb-6">Schedule a Meeting</h3>
              <p className="text-gray-600 mb-4 !text-lg">
                Book a time that works best for you to discuss how RateMatePro can help optimize your rental business.
              </p>
              <button onClick={() => {
                window.location.href =
                  "https://calendar.google.com/calendar/appointments/schedules/AcZssZ3WSw1qjrR2hccl9DJLPHj-Ysh9yS4VBQkXcPCVtSwIcoqaT-m2aaiiG1tQRT0IcJEmRHDEEPJh?gv=true";
              }} className="btn-secondary w-full">
                Schedule Now
                
              </button>
            </div>
            
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Contact;