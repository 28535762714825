import React from 'react';
import { Shield, TrendingUp, BarChart, Clock, Users, Globe } from 'lucide-react';

const WhyChooseUs = () => {
  return (
    <div className="pt-24 pb-16 bg-gradient-radial from-emerald-50 via-white to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Why Choose RateMatePro?</h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            We combine industry expertise with cutting-edge technology to deliver the most comprehensive rental pricing solution for airports and city locations.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
            <TrendingUp className="h-12 w-12 text-primary-500 mb-4" />
            <h3 className="text-xl font-semibold mb-3">Real-Time Airport Rates</h3>
            <p className="text-gray-600">
              Instantly adjust prices based on real-time demand at airports, ensuring optimal revenue during peak travel times.
            </p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
            <Shield className="h-12 w-12 text-primary-500 mb-4" />
            <h3 className="text-xl font-semibold mb-3">City Location Pricing</h3>
            <p className="text-gray-600">
              Smart pricing strategies for city locations, considering local events, seasonality, and market dynamics.
            </p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
            <Clock className="h-12 w-12 text-primary-500 mb-4" />
            <h3 className="text-xl font-semibold mb-3">Custom Pricing Rules</h3>
            <p className="text-gray-600">
              Create and implement custom pricing strategies based on your unique business requirements and market position.
            </p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
            <BarChart className="h-12 w-12 text-primary-500 mb-4" />
            <h3 className="text-xl font-semibold mb-3">Advanced Analytics</h3>
            <p className="text-gray-600">
              Track performance across different locations and optimize pricing strategies with data-driven insights.
            </p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
            <Users className="h-12 w-12 text-primary-500 mb-4" />
            <h3 className="text-xl font-semibold mb-3">Expert Support</h3>
            <p className="text-gray-600">
              Our team helps you develop and fine-tune pricing strategies tailored to your specific market needs.
            </p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
            <Globe className="h-12 w-12 text-primary-500 mb-4" />
            <h3 className="text-xl font-semibold mb-3">Multi-Location Management</h3>
            <p className="text-gray-600">
              Efficiently manage pricing across multiple airports and city locations from a single dashboard.
            </p>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-12 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Rental Business?</h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Join leading car rental companies who trust RateMatePro to optimize their pricing strategy across airports and city locations.
          </p>
          {/* className="px-4 py-2 bg-green-600 text-white font-semibold rounded-lg transition hover:bg-green-700" */}
            <button  onClick={() => {
                window.location.href =
                  "https://calendar.google.com/calendar/appointments/schedules/AcZssZ3WSw1qjrR2hccl9DJLPHj-Ysh9yS4VBQkXcPCVtSwIcoqaT-m2aaiiG1tQRT0IcJEmRHDEEPJh?gv=true";
              }} className="btn-primary">
                Schedule a Demo
                
            </button>
          {/* <button className="btn-primary">Schedule a Demo</button> */}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;