import React, { useState } from 'react';
import { baseURL } from '../backend_url';
import axios from 'axios';
import Swal from 'sweetalert2';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    country: '',
    stdCode: '',
    phoneNumber: '',
    message: '',
  });

  const [formData2, setFormData2] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    country: '',
    stdCode: '',
    phoneNumber: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const countries = [
    { name: 'Afghanistan', code: '+93' },
    { name: 'Albania', code: '+355' },
    { name: 'Algeria', code: '+213' },
    { name: 'Andorra', code: '+376' },
    { name: 'Angola', code: '+244' },
    { name: 'Argentina', code: '+54' },
    { name: 'Armenia', code: '+374' },
    { name: 'Australia', code: '+61' },
    { name: 'Austria', code: '+43' },
    { name: 'Azerbaijan', code: '+994' },
    { name: 'Bahamas', code: '+1-242' },
    { name: 'Bahrain', code: '+973' },
    { name: 'Bangladesh', code: '+880' },
    { name: 'Barbados', code: '+1-246' },
    { name: 'Belarus', code: '+375' },
    { name: 'Belgium', code: '+32' },
    { name: 'Belize', code: '+501' },
    { name: 'Benin', code: '+229' },
    { name: 'Bhutan', code: '+975' },
    { name: 'Bolivia', code: '+591' },
    { name: 'Bosnia and Herzegovina', code: '+387' },
    { name: 'Botswana', code: '+267' },
    { name: 'Brazil', code: '+55' },
    { name: 'Brunei', code: '+673' },
    { name: 'Bulgaria', code: '+359' },
    { name: 'Burkina Faso', code: '+226' },
    { name: 'Burundi', code: '+257' },
    { name: 'Cambodia', code: '+855' },
    { name: 'Cameroon', code: '+237' },
    { name: 'Canada', code: '+1' },
    { name: 'Cape Verde', code: '+238' },
    { name: 'Central African Republic', code: '+236' },
    { name: 'Chad', code: '+235' },
    { name: 'Chile', code: '+56' },
    { name: 'China', code: '+86' },
    { name: 'Colombia', code: '+57' },
    { name: 'Comoros', code: '+269' },
    { name: 'Congo', code: '+242' },
    { name: 'Costa Rica', code: '+506' },
    { name: 'Croatia', code: '+385' },
    { name: 'Cuba', code: '+53' },
    { name: 'Cyprus', code: '+357' },
    { name: 'Czech Republic', code: '+420' },
    { name: 'Denmark', code: '+45' },
    { name: 'Djibouti', code: '+253' },
    { name: 'Dominica', code: '+1-767' },
    { name: 'Dominican Republic', code: '+1-809' },
    { name: 'Ecuador', code: '+593' },
    { name: 'Egypt', code: '+20' },
    { name: 'El Salvador', code: '+503' },
    { name: 'Equatorial Guinea', code: '+240' },
    { name: 'Eritrea', code: '+291' },
    { name: 'Estonia', code: '+372' },
    { name: 'Eswatini', code: '+268' },
    { name: 'Ethiopia', code: '+251' },
    { name: 'Fiji', code: '+679' },
    { name: 'Finland', code: '+358' },
    { name: 'France', code: '+33' },
    { name: 'Gabon', code: '+241' },
    { name: 'Gambia', code: '+220' },
    { name: 'Georgia', code: '+995' },
    { name: 'Germany', code: '+49' },
    { name: 'Ghana', code: '+233' },
    { name: 'Greece', code: '+30' },
    { name: 'Grenada', code: '+1-473' },
    { name: 'Guatemala', code: '+502' },
    { name: 'Guinea', code: '+224' },
    { name: 'Guyana', code: '+592' },
    { name: 'Haiti', code: '+509' },
    { name: 'Honduras', code: '+504' },
    { name: 'Hungary', code: '+36' },
    { name: 'Iceland', code: '+354' },
    { name: 'India', code: '+91' },
    { name: 'Indonesia', code: '+62' },
    { name: 'Iran', code: '+98' },
    { name: 'Iraq', code: '+964' },
    { name: 'Ireland', code: '+353' },
    { name: 'Israel', code: '+972' },
    { name: 'Italy', code: '+39' },
    { name: 'Jamaica', code: '+1-876' },
    { name: 'Japan', code: '+81' },
    { name: 'Jordan', code: '+962' },
    { name: 'Kazakhstan', code: '+7' },
    { name: 'Kenya', code: '+254' },
    { name: 'Kiribati', code: '+686' },
    { name: 'Kuwait', code: '+965' },
    { name: 'Kyrgyzstan', code: '+996' },
    { name: 'Laos', code: '+856' },
    { name: 'Latvia', code: '+371' },
    { name: 'Lebanon', code: '+961' },
    { name: 'Lesotho', code: '+266' },
    { name: 'Liberia', code: '+231' },
    { name: 'Libya', code: '+218' },
    { name: 'Liechtenstein', code: '+423' },
    { name: 'Lithuania', code: '+370' },
    { name: 'Luxembourg', code: '+352' },
    { name: 'Madagascar', code: '+261' },
    { name: 'Malawi', code: '+265' },
    { name: 'Malaysia', code: '+60' },
    { name: 'Maldives', code: '+960' },
    { name: 'Mali', code: '+223' },
    { name: 'Malta', code: '+356' },
    { name: 'Marshall Islands', code: '+692' },
    { name: 'Mauritania', code: '+222' },
    { name: 'Mauritius', code: '+230' },
    { name: 'Mexico', code: '+52' },
    { name: 'Micronesia', code: '+691' },
    { name: 'Moldova', code: '+373' },
    { name: 'Monaco', code: '+377' },
    { name: 'Mongolia', code: '+976' },
    { name: 'Montenegro', code: '+382' },
    { name: 'Morocco', code: '+212' },
    { name: 'Mozambique', code: '+258' },
    { name: 'Myanmar', code: '+95' },
    { name: 'Namibia', code: '+264' },
    { name: 'Nauru', code: '+674' },
    { name: 'Nepal', code: '+977' },
    { name: 'Netherlands', code: '+31' },
    { name: 'New Zealand', code: '+64' },
    { name: 'Nicaragua', code: '+505' },
    { name: 'Niger', code: '+227' },
    { name: 'Nigeria', code: '+234' },
    { name: 'North Korea', code: '+850' },
    { name: 'Norway', code: '+47' },
    { name: 'Oman', code: '+968' },
    { name: 'Pakistan', code: '+92' },
    { name: 'Palau', code: '+680' },
    { name: 'Panama', code: '+507' },
    { name: 'Papua New Guinea', code: '+675' },
    { name: 'Paraguay', code: '+595' },
    { name: 'Peru', code: '+51' },
    { name: 'Philippines', code: '+63' },
    { name: 'Poland', code: '+48' },
    { name: 'Portugal', code: '+351' },
    { name: 'Qatar', code: '+974' },
    { name: 'Romania', code: '+40' },
    { name: 'Russia', code: '+7' },
    { name: 'Rwanda', code: '+250' },
    { name: 'Saint Kitts and Nevis', code: '+1-869' },
    { name: 'Saint Lucia', code: '+1-758' },
    { name: 'Samoa', code: '+685' },
    { name: 'San Marino', code: '+378' },
    { name: 'Saudi Arabia', code: '+966' },
    { name: 'Senegal', code: '+221' },
    { name: 'Serbia', code: '+381' },
    { name: 'Seychelles', code: '+248' },
    { name: 'Sierra Leone', code: '+232' },
    { name: 'Singapore', code: '+65' },
    { name: 'Slovakia', code: '+421' },
    { name: 'Slovenia', code: '+386' },
    { name: 'Solomon Islands', code: '+677' },
    { name: 'Somalia', code: '+252' },
    { name: 'South Africa', code: '+27' },
    { name: 'South Korea', code: '+82' },
    { name: 'Spain', code: '+34' },
    { name: 'Sri Lanka', code: '+94' },
    { name: 'Sudan', code: '+249' },
    { name: 'Suriname', code: '+597' },
    { name: 'Sweden', code: '+46' },
    { name: 'Switzerland', code: '+41' },
    { name: 'Syria', code: '+963' },
    { name: 'Taiwan', code: '+886' },
    { name: 'Tajikistan', code: '+992' },
    { name: 'Tanzania', code: '+255' },
    { name: 'Thailand', code: '+66' },
    { name: 'Togo', code: '+228' },
    { name: 'Tonga', code: '+676' },
    { name: 'Trinidad and Tobago', code: '+1-868' },
    { name: 'Tunisia', code: '+216' },
    { name: 'Turkey', code: '+90' },
    { name: 'Turkmenistan', code: '+993' },
    { name: 'Tuvalu', code: '+688' },
    { name: 'Uganda', code: '+256' },
    { name: 'Ukraine', code: '+380' },
    { name: 'United Arab Emirates', code: '+971' },
    { name: 'United Kingdom', code: '+44' },
    { name: 'United States', code: '+1' },
    { name: 'Uruguay', code: '+598' },
    { name: 'Uzbekistan', code: '+998' },
    { name: 'Vanuatu', code: '+678' },
    { name: 'Vatican City', code: '+379' },
    { name: 'Venezuela', code: '+58' },
    { name: 'Vietnam', code: '+84' },
    { name: 'Yemen', code: '+967' },
    { name: 'Zambia', code: '+260' },
    { name: 'Zimbabwe', code: '+263' }
  ];
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (e) => {
    const selectedCountry = countries.find((c) => c.name === e.target.value);
    setFormData({
      ...formData,
      country: e.target.value,
      stdCode: selectedCountry?.code || '',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form data
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First name is required.';
    if (!formData.lastName) newErrors.lastName = 'Last name is required.';
    if (!formData.email) newErrors.email = 'Email ID is required.';
    if (!formData.companyName) newErrors.companyName = 'Company name is required.';
    if (!formData.country) newErrors.country = 'Country is required.';
    if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone number is required.';
    if (!formData.message) newErrors.message = 'Message is required.';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Clear errors and handle form submission
    setErrors({});
    console.log('Form Data Submitted:', formData);

    const body_payload = {
      formData : formData,
      created_date: new Date()
    }
    const res2 =  axios.post(baseURL + "/save_contact_us_request", body_payload);
    console.log('res2',res2)

    // import Swal from 'sweetalert2';

    Swal.fire({
      title: 'Thank you for reaching out to us.',
      text: "We’ll get back to you shortly.",
      icon: 'success',
      showConfirmButton: false, // Optional: remove confirm button
      timer: 5000, // The alert will stay open for 50 seconds (50,000 ms)
      customClass: {
        popup: 'swal-popup',
      },
      didOpen: () => {
        // Apply inline styles directly to the popup
        const popup = document.querySelector('.swal-popup');
        if (popup) {
          popup.style.width = '400px';
          popup.style.padding = '20px';
          popup.style.backgroundColor = '#212227';
          popup.style.borderRadius = '47px';
          popup.style.color = '#AA7AEB';
          popup.style.fontFamily = 'Manrope, sans-serif';
          popup.style.fontSize = '20px';
          popup.style.fontWeight = '600';
        }

        // Apply styles to the title
        const title = document.querySelector('.swal-popup h2');
        if (title) {
          title.style.textAlign = 'center';
          title.style.fontSize = '24px';
          title.style.color = '#AA7AEB';
          title.style.marginTop = '15px';
        }

        // Apply styles to the content text (same as title styles)
        const content = document.querySelector('.swal-popup .swal-text');
        if (content) {
          content.style.fontSize = '20px'; // Same font size as title
          content.style.color = '#AA7AEB'; // Same color as title
          content.style.textAlign = 'center';
          content.style.marginBottom = '10px';
        }
      }
    }).then(() => {
      // Redirect after the alert closes (timer completes)
      window.location.href = '/'; // Redirect to the home page or any other URL
    });

// window.location.href = '/';


    // Swal.fire('Thank you for reaching out to us. We’ll get back to you shortly.')
    // Swal.fire({
    //   title: 'Thank you for reaching out to us.',
    //   text: "We’ll get back to you shortly.",
    //   icon: 'success',
    //   showConfirmButton: false, // Optional: remove confirm button
    //   timer: 50000, // Optional: auto close after 3 seconds
    //   customClass: {
    //     popup: 'swal-popup',
    //   },
    //   didOpen: () => {
    //     // Apply inline styles directly to the popup
    //     const popup = document.querySelector('.swal-popup');
    //     if (popup) {
    //       popup.style.width = '400px';
    //       popup.style.padding = '20px';
    //       popup.style.backgroundColor = '#212227';
    //       popup.style.borderRadius = '47px';
    //       popup.style.color = '#AA7AEB';
    //       popup.style.fontFamily = 'Manrope, sans-serif';
    //       popup.style.fontSize = '20px';
    //       popup.style.fontWeight = '600';
    //     }
    
    //     // Apply styles to the title
    //     const title = document.querySelector('.swal-popup h2');
    //     if (title) {
    //       title.style.textAlign = 'center';
    //       title.style.fontSize = '24px';
    //       title.style.color = '#AA7AEB';
    //       title.style.marginTop='15px'
    //     }
    
    //     // Apply styles to the content text
    //     const content = document.querySelector('.swal-popup .swal-text');
    //     if (content) {
    //       content.style.fontSize = '18px';
    //       content.style.color = '#fff';
    //       content.style.textAlign = 'center';
    //       content.style.marginBottom = '10px';
    //     }
    //   }
    // });
    setFormData(formData2)

  

    // Add additional form submission logic here
  };

  return (
    <div
      style={{
        maxWidth: '800px',
        margin: '50px auto',
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f9f9f9',
      }}
    >
      <h2
        style={{
          textAlign: 'center',
          color: '#333',
          marginBottom: '30px',
          fontSize: '24px',
          fontWeight: 'bold',
        }}
      >
        Contact Us
      </h2>
      <form onSubmit={handleSubmit}>
        {/* First Name */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>First Name *</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '12px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontSize: '16px',
            }}
          />
          {errors.firstName && <div style={{ color: 'red', marginTop: '5px' }}>{errors.firstName}</div>}
        </div>

        {/* Last Name */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>Last Name *</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '12px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontSize: '16px',
            }}
          />
          {errors.lastName && <div style={{ color: 'red', marginTop: '5px' }}>{errors.lastName}</div>}
        </div>

        {/* Email */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>Email ID *</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '12px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontSize: '16px',
            }}
          />
          {errors.email && <div style={{ color: 'red', marginTop: '5px' }}>{errors.email}</div>}
        </div>

        {/* Company Name */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>Company Name *</label>
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '12px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontSize: '16px',
            }}
          />
          {errors.companyName && <div style={{ color: 'red', marginTop: '5px' }}>{errors.companyName}</div>}
        </div>

        {/* Country */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>Country *</label>
          <select
            name="country"
            value={formData.country}
            onChange={handleCountryChange}
            style={{
              width: '100%',
              padding: '12px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontSize: '16px',
              height:'50px',
              backgroundColor: '#fff',
            }}
          >
            <option value="">Select a country</option>
            {countries.map((country, index) => (
              <option key={index} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
          {errors.country && <div style={{ color: 'red', marginTop: '5px' }}>{errors.country}</div>}
        </div>

        {/* Phone Number */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>Phone Number *</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                padding: '12px',
                backgroundColor: '#e9ecef',
                border: '1px solid #ccc',
                borderRadius: '5px 0 0 5px',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              {formData.stdCode || '+'}
            </span>
            <input
              type="number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              placeholder="Enter your phone number"
              style={{
                flex: 1,
                padding: '12px',
                border: '1px solid #ccc',
                borderLeft: 'none',
                borderRadius: '0 5px 5px 0',
                fontSize: '16px',
              }}
            />
          </div>
          {errors.phoneNumber && <div style={{ color: 'red', marginTop: '5px' }}>{errors.phoneNumber}</div>}
        </div>

        {/* Message */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>Your Message *</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '12px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontSize: '16px',
              height: '120px',
              resize: 'none',
            }}
          ></textarea>
          {errors.message && <div style={{ color: 'red', marginTop: '5px' }}>{errors.message}</div>}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          style={{
            width: '100%',
            padding: '12px',
            backgroundColor: '#007BFF',
            color: '#FFFFFF',
            border: 'none',
            borderRadius: '5px',
            fontSize: '16px',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
